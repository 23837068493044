<template>
  <div class="download">
    <div class="top-bg">
      <div class="logo">
        <img src="../assets/images/code_logo.png" alt="" />
      </div>
      <div class="download-btn">
        <p>
          <el-button icon="icon-appleinc" @click="download(1)"
            >iphone下载</el-button
          >
        </p>
        <p>
          <el-button icon="icon-android2" @click="download(2)"
            >Android下载</el-button
          >
        </p>
        <p class="info">
          也可以在百度助手、91助手、App Store,搜索"车学堂"进行下载安装
        </p>
      </div>
    </div>
    <div class="installation-steps">
      <h2>安装步骤：</h2>
      <el-steps direction="vertical" :active="1">
        <el-step
          title="第一步"
          description="ios9.1以上用户安装完成后，打开苹果手机。设置->通用->设备管理或者描述文件；"
        ></el-step>
        <el-step
          title="第二步"
          description="找到企业及应用下的Jiangsu Retech Education Technology Co., Ltd.点击进入；"
        ></el-step>
        <el-step
          title="第三步"
          description="点击信任Jiangsu Retech Education Technology Co., Ltd.并信任后完成安装，开始使用。"
        ></el-step>
      </el-steps>
    </div>
    <div class="copy">
      <img src="../assets/images/background_bottom.jpeg" alt="" />
    </div>
    <el-dialog
      title="验证提示"
      :visible.sync="centerDialogVisible"
      width="80%"
      center
    >
      <span v-html="html"></span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          v-if="this.button == 1"
          @click="centerDialogVisible = false"
          >确 定</el-button
        >
        <el-button type="primary" v-if="this.button == 2" @click="close"
          >下一步</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="qrCodeDialog"
      width="50%"
      center
      :close-on-click-modal="false"
      class="qr-code-dialog"
    >
      <el-steps :active="active" align-center finish-status="success" simple>
        <el-step title="填写注册信息"></el-step>
        <el-step title="注册成功"></el-step>
      </el-steps>
      <div class="step-item">
        <el-tabs v-model="activeName">
          <el-tab-pane name="0">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="100px"
              class="registration-info"
            >
              <el-form-item label="手机号码：" prop="phone">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
              <el-form-item label="密码：" prop="password">
                <el-input v-model="form.password" show-password></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="repassword">
                <el-input v-model="form.repassword" show-password></el-input>
              </el-form-item>
              <el-form-item label="滑块验证：">
                <drag-verify
                  :width="dragVerify.width"
                  :height="dragVerify.height"
                  :text="dragVerify.text"
                  :success-text="dragVerify.successText"
                  :background="dragVerify.background"
                  :progress-bar-bg="dragVerify.progressBarBg"
                  :completed-bg="dragVerify.completedBg"
                  :handler-bg="dragVerify.handlerBg"
                  :handler-icon="dragVerify.handlerIcon"
                  :text-size="dragVerify.textSize"
                  :success-icon="dragVerify.successIcon"
                  :circle="dragVerify.getShape"
                  @passcallback="passcallback"
                  ref="Verify"
                >
                </drag-verify>
              </el-form-item>
              <el-form-item label="短信验证：" prop="code">
                <el-input v-model="form.code" style="width:65%"></el-input>
                <a
                  style="margin-left:16px;cursor:pointer;"
                  class="code-btn"
                  @click="sendSms"
                  v-html="codeHtml"
                ></a>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane name="1">
            <div class="change-alt">
              <span class="icon-checkmark1"></span>
              <p>恭喜您，注册成功！点击按钮跳转首页登录吧～</p>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          style="margin-top: 12px;"
          @click="next"
          v-html="chickNext"
        ></el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { verify_code, phone_register } from '@/api/ewm'
import { getcode } from '@/api/user'
import dragVerify from 'vue-drag-verify'
export default {
  components: { dragVerify },
  data() {
    return {
      centerDialogVisible: true,
      qrCodeDialog: false,
      button: 2,
      active: 0,
      logining: false,
      dragVerify: {
        width: 250,
        height: 40,
        text: '按住滑块，拖抓到最右边',
        successText: '验证成功',
        progressBarBg: '#7AC23C',
        handlerIcon: 'icon-angle-double-right',
        handlerBg: '#fff',
        successIcon: 'icon-angle-double-right',
        textSize: '14px'
      },
      form: {
        phone: '',
        qrcode: '',
        password: '',
        repassword: '',
        code: ''
      },
      chickNext: '下一步',
      sendTime: 60,
      codeHtml: '获取验证码',
      html: '',
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请填写正确的手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' }
        ],
        repassword: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { min: 6, message: '密码至少6位', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '请填写正确的验证码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.verifyCode()
  },
  methods: {
    passcallback() {
      this.is_slider = this.$refs.Verify.isPassing
    },
    download(type) {
      if (type == 1) {
        window.location.href =
          'https://itunes.apple.com/cn/app/che-xue-tang/id1093395836?mt=8'
      }
      if (type == 2) {
        window.location.href = 'https://www.drivedu.com.cn/chexuetang.apk'
      }
    },
    verifyCode() {
      var param = this.$route.query.code
      if (param == undefined || param == null || param == '') {
        return false
      }
      verify_code({ code: param }).then(res => {
        if (res.code === 0 || res.code === 2) {
          this.html = res.msg
          this.centerDialogVisible = true
          this.button = res.data.button
          this.form.qrcode = res.data.code
        }
      })
    },
    next() {
      if (this.active == 0) {
        if (this.logining == true) {
          return false
        }
        if (!/^1[34578]\d{9}$/.test(this.form.phone)) {
          this.$message({
            message: '请填写正确的手机号码',
            type: 'error'
          })
          return false
        }
        if (this.form.password == '' || this.form.password.length < 6) {
          this.$message({
            message: '请填写至少6位数的密码',
            type: 'error'
          })
          return false
        }
        if (this.form.password !== this.form.repassword) {
          this.$message({
            message: '俩个密码不一致',
            type: 'error'
          })
          return false
        }
        if (!this.$refs.Verify.isPassing) {
          this.$message({
            message: '请移动滑块验证！',
            type: 'error'
          })
          return false
        }
        if (this.form.code == '' || this.form.code.length != 4) {
          this.$message({
            message: '请输入4位数的验证码！',
            type: 'error'
          })
          return false
        }
        this.logining = true
        phone_register(this.form).then(res => {
          this.logining = false
          if (res.code == 0) {
            this.active = 1
            this.chickNext = '跳转首页'
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      }
      if (this.active == 1) {
        this.$router.push({ path: '/' })
      }
    },
    close() {
      this.qrCodeDialog = true
      this.centerDialogVisible = false
    },
    sendSms() {
      if (!/^1[34578]\d{9}$/.test(this.form.phone)) {
        this.$message({
          message: '请填写正确的手机号码',
          type: 'error'
        })
        return false
      }
      if (this.sendTime != 60 || this.logining == true) {
        return false
      }
      this.logining = true
      getcode({ phone: this.form.phone, type: 1 }).then(res => {
        this.logining = false
        if (res.code == 0) {
          let timer = setInterval(() => {
            this.codeHtml = '重新获取(' + this.sendTime + 's)'
            this.sendTime--
            if (this.sendTime <= 0) {
              this.codeHtml = '获取验证码'
              clearInterval(timer)
            }
          }, 1000)
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    }
  },
  computed: {
    activeName: {
      get() {
        return this.active.toString()
      },
      set(newValue) {
        return newValue
      }
    }
  }
}
</script>
