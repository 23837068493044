import request from '@/utils/request'

const VERSION = process.env.VUE_APP_VERSION

export function verify_code(data) {
  return request({
    url: VERSION + '/code/verify',
    method: 'post',
    data: data
  })
}

export function phone_register(data) {
  return request({
    url: VERSION + '/phone/register',
    method: 'post',
    data: data
  })
}
